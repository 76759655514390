import React, { useEffect } from 'react';
import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import _ from 'lodash';

import Text from './Text';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import OutlinedTextField from './OutlinedTextField';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.lightGrey2,
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.lightGrey2,
		},
		'& .MuiInputLabel-outlined': {
			color: theme.colors.lightGrey2,
		},
	},
	menu_item: {
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
		},
	},
}));

const Select = ({
	value,
	handleChange,
	options = [],
	defaultOption,
	containerStyles = { width: 0 },
	text_styles = {},
	menu_styles = {},
	image_styles = {},
	text_props = {},
	selected_title_styles = {},
	selected_title_props = {},
	multiple = false,
	right_icon = null,
	truncate_title,
	disabled,
	left_icon,
	menu_item_class = {},
	show_search = false,
	subtitle_text_direction = 'column',
	disablePadding = false,
	className = '',
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [all_options, set_all_options] = React.useState([...options]);
	const [search_text, set_search_text] = React.useState('');
	const classes = useStyles();
	const is_select_open = Boolean(anchorEl);

	useEffect(() => {
		set_all_options([...options]);
	}, [options]);

	const handleClick = (event) => {
		if (disabled) {
			return;
		}
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (value) => {
		handleChange(value);
		if (!multiple) {
			handleClose();
		}
	};

	const handle_search_change = (value) => {
		set_search_text(value);
		const result = options.filter((o) => o.title.toLowerCase().includes(value.toLowerCase()));
		set_all_options(result);
	};

	const allOptions = [...all_options];
	if (defaultOption) {
		allOptions.unshift(defaultOption);
	}
	let selectedTitle;

	if (multiple) {
		selectedTitle = _.isEmpty(value) ? defaultOption.title : `${value.length} selected`;
	} else {
		_.find(allOptions, (item) => {
			if (item.value == value) {
				selectedTitle = item.title;
			}
		});
		// selectedTitle = (_.find(allOptions, { value }) || {}).title;
	}
	return (
		<>
			<div
				onClick={handleClick}
				className={className}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					border: `1px solid ${theme.colors.primary}`,
					padding: 10,
					borderRadius: 36,
					width: '100%',
					cursor: 'pointer',
					opacity: disabled ? 0.5 : 1,
					...containerStyles,
				}}>
				{left_icon && left_icon}
				<Text style={{ color: theme.colors.primary, ...text_styles, ...selected_title_styles }} {...text_props} {...selected_title_props}>
					{truncate_title ? truncate_title(selectedTitle) : selectedTitle}
				</Text>
				<img
					src={right_icon || ImageLinks.arrow_up_circle_solid}
					style={{ height: 20, width: 20, ...(!is_select_open && { transform: 'rotate(180deg)' }), ...image_styles }}
					alt=''
				/>
			</div>
			<Menu
				anchorEl={anchorEl}
				open={is_select_open}
				onClose={handleClose}
				getContentAnchorEl={null}
				style={{ zIndex: 1501 }}
				MenuListProps={{ disablePadding: disablePadding }}
				PaperProps={{
					style: {
						borderRadius: 10,
						marginTop: 50,
						maxHeight: 400,
						...menu_styles,
					},
				}}>
				{show_search && (
					<OutlinedTextField
						onKeyDown={(e) => e.stopPropagation()}
						placeholder={'Search Teams'}
						InputProps={{
							style: { borderRadius: 36, height: 26, fontSize: 12 },
							endAdornment: <img width={16} height={20} src={ImageLinks.search_light_grey} />,
						}}
						style={{ margin: 0, width: '92%', margin: 8, marginTop: 0 }}
						value={search_text}
						onChange={(e) => handle_search_change(e.target.value)}
						className={classes.root}
					/>
				)}
				{!_.isEmpty(options) &&
					_.map(allOptions.splice(defaultOption ? 1 : 0, allOptions.length), (option, index) => {
						return (
							<MenuItem
								{...menu_item_class}
								className={classes.menu_item}
								disabled={option.disabled}
								key={`menu${index}`}
								onClick={() => {
									if (!option.is_heading) handleSelect(option.value);
								}}
								tabIndex={index}
								style={{
									...(option.is_heading && { opacity: '1' }),
									padding: '8px 14px',
									whiteSpace: 'unset',
									...(option.is_heading && index != 0 && { borderTop: `1px solid ${theme.colors.primary}` }),
								}}>
								{multiple && (
									<img
										src={_.includes(value, option.value) ? ImageLinks.filledCheckbox : ImageLinks.emptyCheckbox}
										width='10'
										height='10'
										style={{ marginRight: 10 }}
										alt=''
									/>
								)}
								{option.option_left_icon && option.option_left_icon}
								<div
									style={{
										display: 'flex',
										flexDirection: subtitle_text_direction,
										justifyContent: 'space-between',
										alignItems: subtitle_text_direction === 'row' ? 'center' : 'flex-start',
										width: '100%',
									}}>
									{option.is_heading && (
										<Text bold style={{ ...text_styles, color: `${theme.colors.black} !important` }} {...text_props}>
											{option.title}
										</Text>
									)}
									{!option.is_heading && (
										<Text style={{ ...text_styles }} {...text_props}>
											{option.title}
										</Text>
									)}
									{option.subtitle && (
										<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2, marginTop: 2 }}>
											{option.subtitle}
										</Text>
									)}
								</div>
							</MenuItem>
						);
					})}
			</Menu>
		</>
	);
};

export default Select;
